<template>
    <div id="orderInfo">
        <div class="l-boxs box-m-t-10"></div>
        <express-info-v1
                :stepInfo="stepInfo.step"
                :title="stepInfo.title"
        ></express-info-v1>
        <div class="card box-m-t-10 box-p-l-20 box-p-r-20">
            <cellV2
                    class="cell cell_split"
                    :type="22"
                    leftText="联系人"
                    :leftIcon="require('@/static/image/icons/user.png')"
            >
                <div class="cell_content">{{orderInfo.name}}</div>
            </cellV2>
            <cellV2
                    class="cell cell_split"
                    :type="2"
                    leftText="联系电话"
                    :leftIcon="require('@/static/image/icons/watch.png')"
                    rightText="联系他"
                    :rightIcon="require('@/static/image/icons/mobile.png')"
                    @onRight="contactUser(orderInfo.mobile)"
            >
                <div class="cell_content">{{orderInfo.mobile}}</div>
            </cellV2>
            <div class="l-flexv box-p-b-16" v-if="orderInfo.address">
                <cellV2
                        class="cell"
                        :type="22"
                        leftText="取货信息"
                        :leftIcon="require('@/static/image/icons/shop.png')"
                        rightText=""
                        rightIcon=""
                >
                    <div class="cell_content">
                        {{orderInfo.address}}
                    </div>
                </cellV2>
                <!--<div class="description">
                </div>-->
            </div>
            <div></div>
        </div>
        <div class="goods-details">
            <div class="good-info">
                <div><img class="goods-pic" :src="orderInfo.cover_path"></div>
                <div class="goods-content">
                    <div class="first-title">{{orderInfo.title}}</div>
                    <div class="second-title">{{orderInfo.fuwu_address}}</div>
                    <div class="third-title">
                        <div>使用人生券数量{{orderInfo.use_credit}}</div>
                        <div style="color: #333333;">¥{{orderInfo.price}}</div>
                    </div>
                </div>
            </div>
            <div class="market-price">
                <div>实付费</div>
                <div style="color: #c10000;font-weight: 600">¥{{orderInfo.order_amount}}</div>
            </div>
            <div class="refund">
                <div class="refund-button" @click="contact">联系客服</div>
                <div v-if="orderInfo.status == 1" class="refund-button" @click="showScanCode"
                     style="color: #F46A17;border-color:#F46A17;margin-left:9px;">扫一扫
                </div>
            </div>

        </div>

        <div class="card box-m-t-10 card-p20">
            <cellV2 class="cell cell_split" :type="5" leftText="订单编号">
                <div class="cell_content">{{ orderInfo.order_sn }}</div>
            </cellV2>
            <cellV2 class="cell" :type="5" leftText="订单时间">
                <div class="cell_content">{{ orderInfo.create_time }}</div>
            </cellV2>
            <cellV2 class="cell" :type="5" leftText="用户留言">
                <div class="cell_content">{{ orderInfo.remark }}</div>
            </cellV2>
        </div>
        <div class="l-boxs box-p-10"></div>


        <nut-popup v-model="visible" get-container="body" :closeable="false"
                   class="services-examine-popup-container">
            <div class="inner-widow">
                <div class="title">
                    订单核销
                </div>
                <div class="image">
                    <img src="https://api.mengjingloulan.com/storage/wechat/services/search.png"/>
                </div>
                <div class="text">
                    是否要核销此码 {{code}}
                </div>
                <div class="button-container">
                    <div class="cancel-button" @click="visible = false">取消</div>
                    <div class="button" @click="doVerify">确定核销</div>
                </div>
            </div>
        </nut-popup>
    </div>
</template>

<script>
  import ExpressInfoV1 from '@T/community/orders/expressStoresInfoV1'
  import storesGoodsListV1 from '@T/community/goodsList/StoresGoodsListV1'
  import cellV2 from '@T/community/cell/cellV2.vue'
  import AddressV2 from '@/template/community/address/addressV2'
  import AddressV1 from '@/template/community/address/addressV1'

  export default {
    name: 'my-services-orders-info',
    components: {
      AddressV1,
      AddressV2,
      ExpressInfoV1,
      storesGoodsListV1,
      cellV2,
    },
    data () {
      return {
        listTypeId: 1,
        order_id: 0,
        orderInfo: {},
        stepInfo: {},
        showResult: false,
        code: '',
        visible: false,
        contactMobile: '',
        canContinue: true
      }
    },
    mounted () {
      let query = this.$route.query
      this.listTypeId = query?.listTypeId || 1
      this.order_id = query?.id
      this.code = query?.code || ''

      document.title = '订单详情'
      this.getOrderInfo()
      this.getContactMobile()
    },
    methods: {
      getContactMobile () {
        this.$api.services.getContactMobile().then(response => {
          this.contactMobile = response.data
        })
      },
      getOrderInfo () {
        this.$api.services.secondOrderInfo({
          list_type_id: this.listTypeId,
          order_id: this.order_id
        }).then(response => {
          this.orderInfo = response.data.orderInfo
          this.stepInfo = response.data.stepInfo
          this.showResult = true

          if (this.code.length > 0 && this.orderInfo.status == 1) {
            this.visible = true
          }
        })
      },
      showScanCode () {
        if (this.canContinue) {
          this.canContinue = false

          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            // type: 'redirectTo',
            url: '/web/scancode/services?listTypeId=' + this.listTypeId + '&order_id=' + this.order_id,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      contactUser(mobile){
        let self = this
        this.$dialog({
          title: '温馨提示',
          content: `确定要给${mobile}打电话吗？`,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn () {
            self.$dialog.closed()
            if (self.$platform.wxsdk.isWechat()) {
              self.$platform.wxsdk.wxRoute({
                type: 'navigateTo',
                url:
                '/web/makePhoneCall/common?mobile=' +
                mobile,
              })
            } else {
              window.location.href = 'tel:' + mobile
            }
          },
        })
      },
      contact () {
        let self = this
        this.$dialog({
          title: '温馨提示',
          content: `确定要给${this.contactMobile}打电话吗？`,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn () {
            self.$dialog.closed()
            if (self.$platform.wxsdk.isWechat()) {
              self.$platform.wxsdk.wxRoute({
                type: 'navigateTo',
                url:
                '/web/makePhoneCall/common?mobile=' +
                this.contactMobile,
              })
            } else {
              window.location.href = 'tel:' + this.contactMobile
            }
          },
        })
      },
      doVerify () {
        if(this.canContinue){
          this.canContinue = false

          this.$api.services.verifyCode({
            code: this.code
          }).then(response => {
            this.$toast.success('核销完成')
            this.visible = false
            this.getOrderInfo()
          }).catch(error => {
            this.$toast.fail(error.message)
          })

          setTimeout(() => {
            this.canContinue = true
          },500)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    #orderInfo {
        display: inline-block;
        width: 100%;
        min-height: 100%;
        background: #f6f6f6;
    }

    @include b(card) {
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        @include m(p20) {
            padding: 20px;
        }
        @include m(p10-20) {
            padding: 10px 20px;
        }
    }

    @include b(cell) {
        height: 44px !important;
        @include e(content) {
            box-sizing: border-box;
            padding-left: 12px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        @include e(split) {
            border-bottom: 1px solid #ebebeb;
        }
    }

    @include b(description) {
        width: 250px;
        align-self: flex-end;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #939393;
    }

    .goods-details {
        width: 375px;
        height: 225px;
        background: #FFFFFF;
        border-radius: 10px;
        margin-top: 10px;
        padding-top: 20px;
        margin-bottom: 18px;
        .good-info {
            width: 335px;
            height: 74px;
            margin: auto;
            border-bottom: 1px solid #EBEBEB;
            padding-bottom: 18px;
            display: flex;
            .goods-pic {
                width: 74px;
                height: 74px;
                border-radius: 4px;
            }
            .goods-content {
                width: 100%;
                height: 74px;
                margin-left: 10px;
                border: 0px solid #ebebeb;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                line-height: 26px;
                overflow: hidden; /*超出部分隐藏*/
                white-space: nowrap; /*不换行*/
                text-overflow: ellipsis; /*超出部分文字以...显示*/
                .first-title {
                    color: #333333;
                }
                .second-title {
                    color: #A2A2A2;
                }
                .third-title {
                    color: #F46A17;
                    display: flex;
                    justify-content: space-between;
                }
            }

        }
        .market-price {
            width: 335px;
            margin: auto;
            height: 50px;
            line-height: 50px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            border-bottom: 1px solid #EBEBEB;
            justify-content: space-between;
        }
        .refund {
            width: 335px;
            margin: auto;
            height: 63px;
            display: flex;
            justify-content: flex-end;
            padding-top: 20px;
            .refund-button {
                padding: 0 18px;
                width: auto;
                height: 36px;
                border-radius: 18px;
                border: 1px solid #D1D1D1;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 36px;
                text-align: center;
            }
        }

    }

    .services-examine-popup-container {
        width: 291px;
        padding: 27px 22px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        .inner-widow {

            display: flex;
            flex-wrap: wrap;

            .title {
                width: 100%;
                font-size: 16px;
                color: #333333;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                margin-bottom: 26px;
            }

            .image {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 14px;

                img {
                    width: 109px;
                    height: 109px;
                }
            }

            .text {
                width: 100%;
                color: #3C3C3C;
                font-size: 14px;
                margin-bottom: 57px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .button-container {
                width: 100%;
                display: flex;

                .cancel-button {
                    flex: 1;
                    height: 44px;
                    background: #f6f6f6;
                    border-radius: 6px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 20px;
                }

                .button {
                    flex: 1;
                    height: 44px;
                    background: linear-gradient(125deg, #FAB476 0%, #EB6100 100%, #EB6100 100%);
                    border-radius: 6px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
</style>